// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/DropDown.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/DropDown.tsx");
  import.meta.hot.lastModified = "1733799214148.7302";
}
// REMIX HMR END

import clsx from 'clsx';
import React from 'react';
import { useRootLoader } from '~/utils/use-root-loader';
import { Slide } from '../Slide/Slide';
import { Link } from '@remix-run/react';
export const DropDown = ({
  collection
}) => {
  _s();
  // useEffect(() => {
  //     const loader = async() => {
  //         const collectionChildren = (await sdk.collection({ slug: collection.slug })).collection;
  //         if (!collectionChildren?.id || !collectionChildren?.name) {
  //             throw new Response('Not Found', {
  //                 status: 404,
  //             });
  //         }
  //     }
  //     loader()
  // }, [])

  const {
    productsByCollection
  } = useRootLoader();
  return <div className={clsx(`absolute top-[100%] left-0 pointer-events-none 
        group-hover/nav:pointer-events-auto group-hover/nav:-translate-y-5 w-full 
        transform opacity-0 group-hover/nav:opacity-100 
        transition-all duration-300 ease-out
        `)}>
      <div className="flex flex-row p-5 z-100 h-[400px] mt-6 border bg-white w-full 
        opacity-0 group-hover/nav:opacity-100 transform -translate-y-2 group-hover/nav:translate-y-0
        transition-all duration-200 ease-out delay-75">
        <div className="flex flex-col w-1/3 space-y-3">
          <h1 className="text-3xl text-[#141c23]">{collection.name}</h1>
          {collection?.children?.map((item, index) => <div key={index}>
              <Link className="font-semibold hover:text-[#141c23] text-gray-400" to={`/collections/${item?.slug}`}>
                {item?.name}
              </Link>
            </div>)}
        </div>
        {productsByCollection.map((item, index) => {
        if (collection.slug === item.slugCollection) {
          return <Slide key={index} products={item.result} />;
        } else return null;
        // return item.result.map(product => (
        // <ProductCard {...product} />
        //  ))
      })}
      </div>
    </div>;
};
_s(DropDown, "tbvEm+7QUdKVSiYnI28g2FsEdaw=", false, function () {
  return [useRootLoader];
});
_c = DropDown;
var _c;
$RefreshReg$(_c, "DropDown");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;