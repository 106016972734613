// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Header.tsx");
  import.meta.hot.lastModified = "1733799214148.7302";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { SearchBar } from '~/components/header/SearchBar';
import { useRootLoader } from '~/utils/use-root-loader';
import { UserIcon } from '@heroicons/react/24/outline';
import { useScrollingUp } from '~/utils/use-scrolling-up';
import { classNames } from '~/utils/class-names';
import { useTranslation } from 'react-i18next';
import { DropDown } from './DropDown';
import { MobileNavBar } from './MobileNavBar';
export function Header({
  onCartIconClick,
  cartQuantity
}) {
  _s();
  const data = useRootLoader();
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const isScrollingUp = useScrollingUp();
  const {
    t
  } = useTranslation();
  // const { collections } = useLoaderData<typeof loader>();

  return <header className={classNames('bg-white sticky top-0 lg:p-0 px-2 right-0 z-50 w-full border py-2')}>
      <div className="bg-black text-white py-2 text-center text-sm">
        WE SHIP ALL OVER THE WORLD
      </div>

      <div className="max-w-6xl mx-auto py-4 flex items-end relative flex-row justify-between space-x-4">
        <MobileNavBar collections={data?.collections} />

        {/* image logo */}
        <h1 className="text-black w-20">
          <Link to="/">
            <img src="/logo2.png" width={190} height={40} color="black" alt={t('commmon.logoAlt')} />
          </Link>
        </h1>

        {/* categories */}
        <div className="items-start hidden sm:block flex-1 flex-shrink mt-7">
          <div className="hidden md:flex items-center space-x-6 px-5">
            {data.collections.map((collection, index) => <div key={`${index}_${collection.id}`} className="group group/nav">
                <Link className="text-sm hover:text-neutral-600 group-hover/nav:underline group-hover/nav:underline-offset-4" to={'/collections/' + collection.slug} prefetch="intent">
                  {collection.name}
                </Link>
                {collection?.children && <DropDown collection={collection} />}
              </div>)}
          </div>
        </div>

        {/* icon */}
        <div className="flex flex-row items-center space-x-2">
          <div className="flex-1">
            <SearchBar className="h-5 w-5"></SearchBar>
          </div>
          <button className="relative w-9 h-9 bg-white bg-opacity-20 rounded text-white p-1" onClick={onCartIconClick} aria-label="Open cart tray">
            <ShoppingBagIcon className="text-black h-5 w-5"></ShoppingBagIcon>
            {cartQuantity ? <div className="absolute rounded-full -top-2 -right-2 bg-primary-600 min-w-6 min-h-6 flex items-center justify-center text-xs p-1">
                {cartQuantity}
              </div> : ''}
          </button>
          <div className="">
            <Link to={isSignedIn ? '/account' : '/sign-in'} className="flex space-x-1">
              <UserIcon className="h-5 w-5"></UserIcon>
            </Link>
          </div>
        </div>
      </div>
    </header>;
}
_s(Header, "WSY+632tkh2ArTqn/iLf/cgu4/A=", false, function () {
  return [useRootLoader, useScrollingUp, useTranslation];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;