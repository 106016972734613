// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/MobileNavBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/MobileNavBar.tsx");
  import.meta.hot.lastModified = "1733481796446.1206";
}
// REMIX HMR END

import React from 'react';
import { Sheet, SheetClose, SheetContent, SheetTrigger } from '../ui/sheet';
import { Link } from '@remix-run/react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Bars3Icon } from '@heroicons/react/24/outline';
export const MobileNavBar = ({
  collections
}) => {
  return <div className="sm:hidden flex justify-start w-[104px]">
      <Sheet>
        <SheetTrigger asChild>
          <Bars3Icon className="w-7 h-7 text-black flex justify-end" />
        </SheetTrigger>
        <SheetContent side="left" className="p-0">
          <Accordion type="single" collapsible className="w-full mt-7 p-3">
            {collections?.map((item, index) => {
            return <AccordionItem key={index} value={`itemn_${index}`}>
                  <AccordionTrigger className="py-2">
                    <SheetClose asChild>
                      <Link to={`/collections/${item?.slug}`} className="text-firstColor text-[14px] leading-[21px] font-semibold">
                        {item?.name}
                      </Link>
                    </SheetClose>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col justify-start pb-2">
                    {item?.children?.map((ele, idx) => <SheetClose key={idx}>
                        <Link to={`collections/${ele?.slug}`}>{ele?.name}</Link>
                      </SheetClose>)}
                  </AccordionContent>
                </AccordionItem>;
          })}
          </Accordion>
        </SheetContent>
      </Sheet>
    </div>;
};
_c = MobileNavBar;
var _c;
$RefreshReg$(_c, "MobileNavBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;